import * as React from "react";
import FourOFour from "../images/f404.inline.svg";
import styled from "styled-components";
import Layout from "../components/layout";
import Button from "./../components/button";
import { _ } from "../utils/i18n";

// styles
const Wrapper = styled.div`
  margin-block: 15rem;
  & .go-back {
    margin: auto;
    width: max-content;
  }
`;
const FourSvg = styled(FourOFour)`
  color: var(--color-primary);
  width: 20rem;
  height: auto;
`;

const CodeStyles = styled.code`
  color: var(--color-primary);
  padding: 4;
  background-color: var(--color-secondary);
  font-size: 1.25rem;
  border-radius: var(--border-radius);
`;
const Text = styled.p`
  margin-block: 3rem;
  font-size: 1.3rem;
  text-align: center;
`;

// markup
const NotFoundPage = () => {
  return (
    <Layout>
      <Wrapper>
        <FourSvg />
        <Text>{_("Siden ble ikke funnet")}</Text>
        <Button
          url="/"
          variant="filled"
          label={_("Tilbake til forside")}
          innerClassName="go-back"
        />
        {process.env.NODE_ENV === "development" ? (
          <>
            <Text>
              Try creating a page in <CodeStyles>src/pages/</CodeStyles>.
            </Text>
          </>
        ) : null}
      </Wrapper>
    </Layout>
  );
};

export default NotFoundPage;
